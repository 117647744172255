import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl1 = 'https://myemail-verifier.com:3000';

const Home = () => {
  const [email, setEmail] = useState('');
  const [email1, setEmail1] = useState(false);
  const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail1(false);
    const endpoint = 'singleemailchecker';
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl1}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setLoading(false);
      const data = await response.json();      
      setEmail1(data.results[0]);
    } 
    catch (error) {
      console.error(error);
    }
  };

  return (
  	<div>
        <div className="email_checker_mainsec ">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="title_sec">
                  <h3>Single Email Verification</h3>
                </div>
                <div className="email_verification_box">
                  <div className="form_mainsec" style={{paddingBottom: '10px'}}>
                    <form>
                      <div className="form-group">
                        <input  
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required 
                          className="form-control" 
                          placeholder="Email address" 
                        />
                        <button type="submit" onClick={handleSubmit} class="btn-style">Validate</button>
                      </div>
                      { email1 ? <div className="email_validat_msg">Email is {email1.message}</div> : null }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <div className="title_sec">
                  <h3>OR</h3>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <div className="btn_mainsec">
                  <a href={`${homeUrl}/bulk-upload`} className="btn_style">Bulk Upload</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className="loader_mainsec">
            <div className="loader_innerbox">
              <div className="spinner_box">
                <div className="spinner"></div>
              </div>
              <div className="loader_contnt">
                <p>Please wait.....</p>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
export default Home;