import React, {  } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import BulkUpload from './components/BulkUpload';
import Login from './components/Login';
import Register from './components/Register';
import Forgotpassword from './components/Forgotpassword';
import Searchdomain from './components/Searchdomain';
import Emailchecker from './components/Emailchecker';
import { saveSessionData, getSessionData } from './components/utils';
const homeUrl = process.env.REACT_APP_HOME_URL;

const App = () => {
  const user = getSessionData('user');
  const logout = () => {
    saveSessionData('user', null);
    window.location.href = homeUrl+'/login';
  };
  return (
    <Router>
      <ScrollToTop />
      <div className="header_mainsec fixed_sec">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href={`${homeUrl}`}><img src={`${homeUrl}/assets/img/main-logo.png`} /></a>
            {user ? <div className="right_box">
              <div className="dropdown profile_boxsec">
                <a className="dropdown_toggle" href="#">
                  <span className="profile_imgbox">
                    <img src={`${homeUrl}/assets/img/profile-default-img.png`} />
                  </span>
                
                  <span className="profile_txt">
                    {user.name} <i className="fas fa-chevron-down"></i>
                  </span>
                </a>
                
                <div className="dropdown-menu profile-dropdown">                
                  <a href="#" className="dropdown-item notify-item" onClick={logout}>
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout </span>
                  </a>
                </div>
              </div>
            </div> :
            <div className="right_box">
              <div className="btn_mainsec">
                <a className="btn_style" href={`${homeUrl}/login`}>Get Access</a>
              </div>
            </div> }
          </div>
        </nav>
      </div>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/bulk-upload" element={<BulkUpload />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Register />} />
        <Route exact path="/forgot-password" element={<Forgotpassword />} />
        <Route exact path="/search-domain" element={<Searchdomain />} />
        <Route exact path="/emailchecker" element={<Emailchecker />} />
      </Routes>

    <div class="footer_mainsec">
		<div class="container">
			<div class="row">
				<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<div class="logo_box">
						 <a href={`${homeUrl}`}><img src={`${homeUrl}/assets/img/main-logo.png`} /></a>
					</div>
				</div>
				
				<div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
					<div class="footer_menu">
						<ul>
							<li><a href="#">Terms & Conditions</a></li>
							<li><a href="#">Privacy Policy</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
    </Router>
  );
};

export default App;
