import { useState } from 'react';
import Papa from 'papaparse';
import { Navigate } from 'react-router-dom';
import { getSessionData } from './utils';

const apiUrl = process.env.REACT_APP_API_URL;
const apiUrl1 = 'https://myemail-verifier.com:3000';
function Emailchecker() {
  const user = getSessionData('user');
  const [message, setMessage] = useState(false);
  const [message1, setMessage1] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [emaildata, setEmaildata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState(null);
  const [results, setResults] = useState([]);
  const [results1, setResults1] = useState([]);
  const [email, setEmail] = useState('');
  const [email1, setEmail1] = useState(false);
  const [validemail, setValidemail] = useState(0);
  const [invalidemail, setInvalidemail] = useState(0);
  const [totalemail, setTotalemail] = useState(0);

  if(!user) {
    return <Navigate to="/login" replace />;
  }
  if(!user.payment_status) {
    return <Navigate to="/" replace />;
  }

  const handleFileChange = (event) => {
    setFilename(event.target.files[0].name);
    setCsvFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (csvFile) {
      const reader = new FileReader();

      reader.onload = async function (e) {
        const csvData = e.target.result;
        await sendCSVData(csvData);
      };

      reader.readAsText(csvFile);
    } else {
      alert('Please select a CSV file');
    }
  };

  const sendRequest = async (rk) => {
    const endpoint = 'emailchecker';
    const response = await fetch(`${apiUrl1}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: rk }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data1 = await response.json();
    return data1;
  };

  const sendCSVData = async (csvData) => {
      const timer1 = setTimeout(() => {
        setMessage1('Still at It!');
        setMessage('I’m still crunching those numbers. It’s taking a bit longer, but don’t worry, I haven’t forgotten about you. Thanks for hanging in there!');
      }, 15 * 60 * 1000);

      const timer2 = setTimeout(() => {
        setMessage1('Almost There!');
        setMessage('You’re doing great! I’m still processing your list like a pro. Just a little more time, and I’ll have everything ready for you.');
      }, 30 * 60 * 1000);

      const timer3 = setTimeout(() => {
        setMessage1('Final Stretch!');
        setMessage('We’re in the home stretch now! Your list is almost ready. Thanks for being so patient – you’re a real trooper!');
      }, 45 * 60 * 1000);
    // Use PapaParse to parse the CSV data
    Papa.parse(csvData, {
      header: false, // If CSV doesn't contain headers, set this to false
      skipEmptyLines: true,
      complete: async (results) => {
        const rows = results.data;
        save_json_file(rows);
        setEmaildata([]);
        setResults([]);
        setLoading(true);
        var v_email = 0;
        var in_email = 0;
        for (let i = 1; i < rows.length; i++) {
          if (rows[i].length > 0) { // Avoid sending empty rows
            try {
              const rk = rows[i];
              if (rk[0] !== '') {
                console.log(rk);
                const result = await sendRequest(rk);       
                console.log(result);
                if (result.results.length > 0 && result.results[0].status) {
                  v_email = v_email+1;
                  var rk1 = rk;
                  setResults((prevResults) => [
                    ...prevResults,
                    { 
                      'WarpLeadsId':rk1[0],
                      'FirstName':rk1[1],
                      'LastName':rk1[2],
                      'JobTitle':rk1[3],
                      'PersonLinkedInUrl':rk1[4],
                      'PersonCountry':rk1[5],
                      'CompanyName':rk1[6],
                      'CompanyIndustry':rk1[7],
                      'CompanySize':rk1[8],
                      'CompanyWebsite':rk1[9],
                      'CompanyCountry':rk1[10],
                      'CompanyCity':rk1[11],
                      'WorkEmail':rk1[12],
                      'PersonalEmail':rk1[13],
                      'Phone1':rk1[14],
                      'Phone2':rk1[15],
                      'Status': "valid"
                    }
                  ]);
                }
                else {
                  var rk2 = rk;
                  setResults1((prevResults) => [
                    ...prevResults,
                    { 
                      'WarpLeadsId': rk2[0],
                      'FirstName':rk2[1],
                      'LastName':rk2[2],
                      'JobTitle':rk2[3],
                      'PersonLinkedInUrl':rk2[4],
                      'PersonCountry':rk2[5],
                      'CompanyName':rk2[6],
                      'CompanyIndustry':rk2[7],
                      'CompanySize':rk2[8],
                      'CompanyWebsite':rk2[9],
                      'CompanyCountry':rk2[10],
                      'CompanyCity':rk2[11],
                      'WorkEmail':rk2[12],
                      'PersonalEmail':rk2[13],
                      'Phone1':rk2[14],
                      'Phone2':rk2[15],
                      'Status': "invalid"
                    }
                  ])
                  in_email = in_email+1;
                }
              }
            } catch (error) {
              console.error('Error processing row:', i, error);
            }
          }
        }
        
        setValidemail(v_email);
        setInvalidemail(in_email);
        setTotalemail(v_email+in_email);
        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
        setMessage(false);
        setMessage1(false);
        setLoading(false);
      }
    });
  };

  const save_json_file = async (rows) => {
    try {
      const endpoint = 'save-json';
      const response = await fetch(`${apiUrl1}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: user.email, userid: user.id, rows: rows, status: 0 }),
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('API Response:', data);
    } catch (error) {
      console.error('Fetch Error:', error.message);
    }
  };

  const downloadCSV = () => {
    const edata = results;
    if (edata.length > 0) {
      const headers = Object.keys(edata[0]).join(",") + "\n";
      // const rows = edata.map((item) => Object.values(item).join(",")).join("\n");
      const rows = edata.map((item) => 
        Object.values(item)
          .map((value) => {
            if (typeof value === 'string') {
              // Escape double quotes and wrap the value in double quotes if it contains a comma, newline, or double quote
              value = value.replace(/"/g, '""'); // Escape double quotes
              if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                value = `"${value}"`;
              }
            }
            return value;
          })
          .join(",")
      ).join("\n");
      const csvContent = headers + rows;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute("download", "valid-emails.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No data available to export.");
    }
  };

  const downloadCSV1 = () => {
    const edata1 = results1;
    if (edata1.length > 0) {
      const headers = Object.keys(edata1[0]).join(",") + "\n";
      // const rows = edata.map((item) => Object.values(item).join(",")).join("\n");
      const rows = edata1.map((item) => 
        Object.values(item)
          .map((value) => {
            if (typeof value === 'string') {
              // Escape double quotes and wrap the value in double quotes if it contains a comma, newline, or double quote
              value = value.replace(/"/g, '""'); // Escape double quotes
              if (value.includes(',') || value.includes('\n') || value.includes('"')) {
                value = `"${value}"`;
              }
            }
            return value;
          })
          .join(",")
      ).join("\n");
      const csvContent = headers + rows;

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.href = url;
      link.setAttribute("download", "invalid-emails.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No data available to export.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail1(false);
    const endpoint = 'singleemailchecker';
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl1}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      setLoading(false);
      const data = await response.json();      
      setEmail1(data.results[0]);
    } 
    catch (error) {
      console.error(error);
    }
  };

  const handleClosePopup = () => {
    setValidemail(0);
    setInvalidemail(0);
  };
  
  return (
    <div className="email_checker_mainsec">
      <div className="container">
        <div className="row">
		      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3"></div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="fileinput_sec">
      				<div className="title_sec">
      					<h3>Upload your Email List</h3>
      				</div>
				
              <div className="upload_box">
                <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  required
                  className="form-control"
                />
                <div className="upload_txt">
                  <i className="fas fa-upload"></i>
                  <div className="contnt_txt">
                    {filename ? <p>{filename}</p> : <p>Choose a file or drag it here.</p>}
                  </div>
                </div>
              </div>
              <div className="btn_mainsec">
                <button className="btn_style" onClick={handleUpload}>Submit</button>
              </div>
            </div>
          </div>
        </div>
       </div>
      {loading && (
        <div className="loader_mainsec">
			<div className="loader_innerbox">
				<div className="spinner_box">
					<div className="spinner"></div>
				</div>
				{ message ? (
          <div className="loader_contnt">
            <p>Please wait.....</p>
            { message1 ? <p> {message1} </p> : null }
            { message ? <p> {message} </p> : null }
          </div> 
          ) : (
          <div className="loader_contnt">
            <p>Please wait.....</p>
            <p>Hold Tight – I'm Working My Magic!</p>
            <p>Please don’t close this window – I’m hard at work on your list, and believe me, I’ve got this under control.</p>
            <br/>
            <p>Depending on the size of your list, this could take up to an hour. But hey, good things take time! I’ll let you know right here <br/>when everything’s done. Thanks for your patience!</p>
          </div> 
        )}
			</div>
        </div>
      )}
	  
	  
    {validemail && (
    <div>
      <div className="modal fade infopopupModal in show" id="infopopupModal" tabindex="-1" aria-labelledby="exampleModalLabel" style={{display:'block'}}>
  		  <div className="modal-dialog modal-dialog-centered">
  			<div className="modal-content">
  			  <div className="modal-body">
  				<div className="closebtn">
  					<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClosePopup}></button>
  				</div>
  				 
  				<div className="contnt_mainsec">
  					<h2>Filtered Email Data</h2>
					
  					<div className="total_emailbox">
						<h4>Total Emaill {totalemail}</h4>
					</div>
					
  					<div className="email_render_class">
						<span>Valid Email: {validemail} </span>
						
						<div className="btn_mainsec">
							<button className="btn_style" onClick={downloadCSV}>Export CSV</button>
						</div>
					</div>
					
  					<div className="email_render_class">
						<span>Invalid Email: {invalidemail} </span>
						
						<div className="btn_mainsec">
							<button className="btn_style" onClick={downloadCSV1}>Export CSV</button>
						</div>
					</div>
  				</div>
  			  </div>
  			  
  			  <div className="modal-footer">
  				  <div className="btn_mainsec">
    					
  				  </div>
  			  </div>
  			</div>
  		  </div>
  		</div>
    </div>
    )}
    </div>
  );
}

export default Emailchecker;
